import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Energisk</title>
        <meta property="og:title" content="Energisk" />
      </Helmet>
      <div data-role="Header" className="home-navbar-container">
        <div className="home-navbar">
          <div data-role="BurgerMenu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
          <a href="#hero" className="home-link">
            <div className="home-logo">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="home-image"
              />
              <span className="home-text">
                <span>Energisk</span>
                <br></br>
              </span>
            </div>
          </a>
          <div className="home-links-container">
            <a href="#features" className="home-link1 Anchor">
              Download
            </a>
            <a href="#features" className="home-link2">
              features
            </a>
            <a href="#screenshots" className="home-link3 Anchor">
              Screenshots
            </a>
            <a href="#about" className="home-link4 Anchor">
              About
            </a>
          </div>
          <div className="home-cta-container">
            <a
              href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
              target="_blank"
              rel="noreferrer noopener"
              className="home-cta-btn Anchor button"
            >
              DOWNLOAD
            </a>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-top">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="home-image01"
              />
              <span className="home-text03">
                <span>Energisk</span>
                <br></br>
              </span>
              <div data-role="CloseMobileMenu" className="home-container1">
                <svg viewBox="0 0 1024 1024" className="home-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-mid">
              <div className="home-links-container1">
                <a href="#features" className="home-link5 Anchor">
                  features
                </a>
                <a href="#services" className="home-link6 Anchor">
                  Screenshots
                </a>
                <a href="#about" className="home-link7 Anchor">
                  About
                </a>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
                target="_blank"
                rel="noreferrer noopener"
                className="home-cta-btn1 button Anchor"
              >
                GET IT ON GOOGLE PLAY
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="hero" className="home-hero">
        <div className="home-hero-text-container">
          <h1 className="home-heading Section-Heading">
            Energisk - Electricity Prices for Android and Wear OS
          </h1>
          <span className="home-text06">
            <span>
              Energisk is a mobile app that provides real-time electricity
              prices across several regions in the European energy market. It is
              available on smartphones and also supports Wear OS, offering you
              the convenience of checking energy costs from your wrist.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              Energisk focuses on delivering a simple and user-friendly
              experience, making it easy for you to stay updated on changes in
              energy prices.
            </span>
            <br></br>
            <br></br>
            <span>
              {' '}
              Whether you&apos;re a homeowner, business owner, or simply
              interested in monitoring electricity prices, Energisk&apos;s clean
              and intuitive interface and accurate data makes it a convenient
              tool for staying informed about energy costs.
            </span>
          </span>
          <a
            href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
            target="_blank"
            rel="noreferrer noopener"
            className="home-cta-btn2 button Anchor"
          >
            GET IT ON GOOGLE PLAY
          </a>
        </div>
        <img
          alt="image"
          src="/playground_assets/phone-and-watch-1500w.jpg"
          className="home-image02"
        />
      </div>
      <div className="home-section-separator"></div>
      <div id="features" className="home-features">
        <div className="home-heading-container">
          <h2 className="home-text14">
            <span className="Section-Heading home-text15">Features</span>
            <br></br>
          </h2>
        </div>
        <div className="home-cards-container">
          <div className="home-feature-card">
            <img
              alt="image"
              src="/playground_assets/day-ahead-price-200w.png"
              className="home-image03"
            />
            <span className="home-heading1 Card-Heading">Day-Ahead Prices</span>
            <span className="home-text17">
              <span className="Card-Text">
                The app offers the ability to view day-ahead electricity prices,
                allowing users to plan and make informed decisions about their
                energy usage. With access to accurate, up-to-date information,
                users can make smart choices about when to use energy and take
                control of their energy costs.
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-feature-card1">
            <img
              alt="image"
              src="/playground_assets/key-statistics-200h.png"
              className="home-image04"
            />
            <span className="home-heading2 Card-Heading">Key Statistics</span>
            <span className="home-text20">
              Energisk displays key statistics about electricity prices,
              providing users with a complete picture of the energy costs for a
              given day. With access to the current price, the daily average
              price, and the hours with the highest and lowest price, users are
              empowered to manage their energy consumption effectively.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="home-feature-card2">
            <img
              alt="image"
              src="/playground_assets/screenshot_20230204_093410_wear_framed-200w.png"
              className="home-image05"
            />
            <span className="home-heading3 Card-Heading">Wear OS Support</span>
            <span className="home-text21">
              For added convenience, Energisk supports for Wear OS, allowing
              users to check prices from their wrist. This gives users greater
              flexibility and control over their energy usage, as they can check
              energy costs at a glance without having to pull out their phone.
            </span>
          </div>
        </div>
      </div>
      <div className="home-section-separator1"></div>
      <div id="screenshots" className="home-screenshots">
        <div className="home-heading-container1">
          <h2 className="home-text22">
            <span className="Section-Heading home-text23">Screenshots</span>
            <br></br>
          </h2>
        </div>
        <div className="home-grid">
          <div className="home-service-card">
            <div className="home-card-content">
              <img
                alt="image"
                src="/playground_assets/screenshot3-500h.png"
                className="home-image06"
              />
              <span className="home-text25 Card-Text">
                Main Screen on Wear OS
              </span>
            </div>
          </div>
          <div className="home-service-card1">
            <div className="home-card-content1">
              <img
                alt="image"
                src="/playground_assets/screenshot4-500h.png"
                className="home-image07"
              />
              <span className="home-text26 Card-Text">
                Settings Screen on Wear OS
              </span>
            </div>
          </div>
          <div className="home-service-card2">
            <div className="home-card-content2">
              <img
                alt="image"
                src="/playground_assets/screenshot1-500w.jpg"
                className="home-image08"
              />
              <span className="home-text27">
                <span>Main Screen on Android</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-service-card3">
            <div className="home-card-content3">
              <img
                alt="image"
                src="/playground_assets/screenshot2-500w.jpg"
                className="home-image09"
              />
              <span className="home-text30 Card-Text">
                Settings Screen on Android
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator2"></div>
      <div id="about" className="home-about">
        <div className="home-heading-container2">
          <h2 className="home-text31 Section-Heading">About Energisk</h2>
          <span className="home-secondary-text Section-Text">
            {' '}
            Energisk was created by me, Olav S. Thoresen. To see my other
            projects, visit my personal website by clicking the button below.
          </span>
          <a href="https://olav.ninja" className="home-cta-btn3 button Anchor">
            VISIT THE CREATOR&apos;S WEBSITE
          </a>
        </div>
        <a href="https://olav.ninja" className="home-cta-btn4 button Anchor">
          VISIT THE CREATOR&apos;S WEBSITE
        </a>  
      </div>
    </div>
  )
}

export default Home
