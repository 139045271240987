import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './help.css'

const Help = (props) => {
  return (
    <div className="help-container">
      <Helmet>
        <title>Help - Energisk</title>
        <meta property="og:title" content="Help - Energisk" />
      </Helmet>
      <div data-role="Header" className="help-navbar-container">
        <div className="help-navbar">
          <div data-role="BurgerMenu" className="help-burger-menu">
            <svg viewBox="0 0 1024 1024" className="help-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
          <Link to="/" className="help-navlink">
            <div className="help-logo">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="help-image"
              />
              <span className="help-text">
                <span>Energisk</span>
                <br></br>
              </span>
            </div>
          </Link>
          <div className="help-links-container">
            <a href="#features" className="help-link Anchor">
              Download
            </a>
            <Link to="/" className="help-link1">
              features
            </Link>
            <Link to="/" className="help-link2 Anchor">
              Screenshots
            </Link>
            <Link to="/" className="help-link3 Anchor">
              About
            </Link>
          </div>
          <div className="help-cta-container">
            <a
              href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
              target="_blank"
              rel="noreferrer noopener"
              className="help-cta-btn Anchor button"
            >
              DOWNLOAD
            </a>
          </div>
          <div data-role="MobileMenu" className="help-mobile-menu">
            <div className="help-top">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="help-image1"
              />
              <span className="help-text03">
                <span>Energisk</span>
                <br></br>
              </span>
              <div data-role="CloseMobileMenu" className="help-container1">
                <svg viewBox="0 0 1024 1024" className="help-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="help-mid">
              <div className="help-links-container1">
                <Link to="/" className="help-link4 Anchor">
                  features
                </Link>
                <Link to="/" className="help-link5 Anchor">
                  Screenshots
                </Link>
                <Link to="/" className="help-link6 Anchor">
                  About
                </Link>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
                target="_blank"
                rel="noreferrer noopener"
                className="help-cta-btn1 button Anchor"
              >
                GET IT ON GOOGLE PLAY
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="help" className="help-help">
        <div className="help-heading-container">
          <h2 className="help-text06">
            <span className="Section-Heading help-text07">FAQ</span>
            <br></br>
          </h2>
        </div>
        <span className="help-text09">
          <span className="help-text10">Q:</span>
          <span> What is Energisk?</span>
          <br></br>
          <span className="help-text13">A:</span>
          <span>
            {' '}
            Energisk is a mobile app that provides real-time insights into
            electricity prices across different regions.
          </span>
          <br></br>
          <br></br>
          <span className="help-text17">Q:</span>
          <span>
            {' '}
            Is Energisk available on both smartphones and smartwatches?
          </span>
          <br></br>
          <span className="help-text20">A:</span>
          <span>
            {' '}
            Yes, Energisk is available on both smartphones and smartwatches with
            Wear OS support.
          </span>
          <br></br>
          <br></br>
          <span className="help-text24">Q:</span>
          <span>
            {' '}
            What information does Energisk provide about electricity prices?
          </span>
          <br></br>
          <span className="help-text27">A:</span>
          <span>
            {' '}
            Energisk provides the current price, average price for the day, and
            the hours with the highest and lowest price. It also offers a clear
            and uncluttered interface to stay informed on changes in energy
            prices.
          </span>
          <br></br>
          <br></br>
          <span className="help-text31">Q:</span>
          <span> Can Energisk help me control my energy consumption?</span>
          <br></br>
          <span className="help-text34">A:</span>
          <span>
            {' '}
            Yes, by providing a comprehensive overview of energy costs and
            enabling users to plan and make informed decisions about their
            energy usage, Energisk can help users take control of their energy
            consumption.
          </span>
          <br></br>
          <br></br>
          <span className="help-text38">Q:</span>
          <span> Which energy markets does Energisk support?</span>
          <br></br>
          <span className="help-text41">A:</span>
          <span>
            {' '}
            Currently, Energisk only supports European energy markets.
          </span>
          <br></br>
          <br></br>
          <span className="help-text45">Q:</span>
          <span> Who created Energisk?</span>
          <br></br>
          <span className="help-text48">A:</span>
          <span> Energisk was created by Olav S. Thoresen.</span>
          <br></br>
          <br></br>
          <span className="help-text52">Q:</span>
          <span> Is the app easy to use?</span>
          <br></br>
          <span className="help-text55">A:</span>
          <span>
            {' '}
            Yes, Energisk has a focus on simplicity and ease of use, offering a
            clean and intuitive interface for users to stay informed about
            electricity prices.
          </span>
        </span>
      </div>
    </div>
  )
}

export default Help
