import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './privacy.css'

const Privacy = (props) => {
  return (
    <div className="privacy-container">
      <Helmet>
        <title>Privacy - Energisk</title>
        <meta property="og:title" content="Privacy - Energisk" />
      </Helmet>
      <div data-role="Header" className="privacy-navbar-container">
        <div className="privacy-navbar">
          <div data-role="BurgerMenu" className="privacy-burger-menu">
            <svg viewBox="0 0 1024 1024" className="privacy-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
          <Link to="/" className="privacy-navlink">
            <div className="privacy-logo">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="privacy-image"
              />
              <span className="privacy-text">
                <span>Energisk</span>
                <br></br>
              </span>
            </div>
          </Link>
          <div className="privacy-links-container">
            <a href="#features" className="privacy-link Anchor">
              Download
            </a>
            <Link to="/" className="privacy-link1">
              features
            </Link>
            <Link to="/" className="privacy-link2 Anchor">
              Screenshots
            </Link>
            <Link to="/" className="privacy-link3 Anchor">
              About
            </Link>
          </div>
          <div className="privacy-cta-container">
            <a
              href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
              target="_blank"
              rel="noreferrer noopener"
              className="privacy-cta-btn button Anchor"
            >
              DOWNLOAD
            </a>
          </div>
          <div data-role="MobileMenu" className="privacy-mobile-menu">
            <div className="privacy-top">
              <img
                alt="image"
                src="/playground_assets/lightning_icon_rounded.svg"
                className="privacy-image1"
              />
              <span className="privacy-text03">
                <span>Energisk</span>
                <br></br>
              </span>
              <div data-role="CloseMobileMenu" className="privacy-container1">
                <svg viewBox="0 0 1024 1024" className="privacy-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="privacy-mid">
              <div className="privacy-links-container1">
                <Link to="/" className="privacy-link4 Anchor">
                  features
                </Link>
                <Link to="/" className="privacy-link5 Anchor">
                  Screenshots
                </Link>
                <Link to="/" className="privacy-link6 Anchor">
                  About
                </Link>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=app.energisk.mobile"
                target="_blank"
                rel="noreferrer noopener"
                className="privacy-cta-btn1 button Anchor"
              >
                GET IT ON GOOGLE PLAY
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="privacy" className="privacy-privacy">
        <div className="privacy-heading-container">
          <h2 className="privacy-text06">
            <span className="Section-Heading privacy-text07">
              Privacy Policy
            </span>
            <br></br>
          </h2>
        </div>
        <span className="privacy-text09">
          <span>
            This privacy policy explains how the Energisk app (we, or us)
            collects, uses, shares and protects your information.
          </span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span className="privacy-text13">Information collection and use</span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span>
            Energisk does not collect any personal information from users. The
            app does not require users to register or provide any personal
            information. The app does not use cookies or any other tracking
            technologies to collect information about you.
          </span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span className="privacy-text19">Sharing of information</span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span>
            Energisk does not share any information with third parties. We do
            not sell or rent your information to anyone.
          </span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span className="privacy-text25">Protection of information</span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span>
            Energisk is committed to protecting your information. We implement
            reasonable security measures to protect your information from
            unauthorized access, destruction, use, modification, or disclosure.
          </span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span className="privacy-text31">Changes to this policy</span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span>
            Energisk may update this policy from time to time. We will notify
            you of any changes by posting the new policy on the app or by other
            means. You are advised to review this policy periodically for any
            changes.
          </span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span className="privacy-text37">Contact us</span>
          <br className="Anchor"></br>
          <br className="Anchor"></br>
          <span>
            If you have any questions or concerns about this policy, please
            contact us at contact@energisk.app.
          </span>
        </span>
      </div>
    </div>
  )
}

export default Privacy
